import React, { useEffect } from "react";
import normalize from "normalize-path";
import { Helmet } from "react-helmet";
import { PostEntryContent } from "./PostEntryContent";
import { PostEntryInfo } from "./PostEntryInfo";
import { PostEntryMedia } from "./PostEntryMedia";
import { PostEntryTitle } from "./PostEntryTitle";
import SocialShare from '../SocialShare';


export const PostEntry = ({
  isFirst = false,
  post,
  ctx,
  location,
  className = "",
  ...props
}) => {
  const media = post.featuredImage
    ? post.featuredImage.node.localFile?.childImageSharp?.original.src
    : null;

  const { pageTemplate, skipTitle } = post.headlesswp;


  // Attach script for utteranc.es on componentDidMount
  useEffect(() => {
    let script = document.createElement("script");
    let anchor = document.getElementById("inject-comments-for-uterances");

    anchor.innerHTML = "";

    script.setAttribute("src", "https://utteranc.es/client.js");
    script.setAttribute("crossorigin", "anonymous");
    script.setAttribute("async", true);
    script.setAttribute("repo", "Dunebook/codinghelp");
    script.setAttribute("issue-term", "pathname");
    script.setAttribute("theme", "dark-blue");
    anchor.appendChild(script);
  }, []);

  return (
    <>
    <Helmet>
    <title>{post.title}</title>
    <meta property="og:title" content={post.title} />
    <meta property="og:description" content={post.excerpt ? post.excerpt : ""} />
    <meta property="og:image" content={media} />
    <meta property="og:url" content={`https://codinghelp.io/${post.uri}`} />
    <meta property="og:type" content="article" />
  </Helmet>

    <article className={`${className}`} {...props}>
      {post.featuredImage && (
      
      <PostEntryMedia
      imageLoading="eager"
      post={post}
      location="single"
      className={`w-full h-72 md:h-72 sm:h-48 xs:h-32 rounded-lg shadow-md hover:shadow-lg transition duration-300 transform hover:-rotate-2`}
      imgStyle={{
        objectFit: "cover",
        borderRadius: "inherit",
        filter: "brightness(80%)",
      }}
      overlay={
        <div className="absolute inset-0 flex items-center justify-center">
          <h2 className="text-2xl font-bold text-white">{post.title}</h2>
        </div>
      }
      
    />
    
    
    
    
    
      )}
      <div
        className={`${pageTemplate === "full width" ? "center-container" : ""
          } content`}
      >
        <div className={` space-y-5 px-5 sm:px-10 pb-10`}>
          {!skipTitle && (
            <PostEntryTitle post={post} location={location} className="mb-5" />
          )}
          <PostEntryInfo post={post} className="mb-3" />

          

          <PostEntryContent post={post} location={location} />


          <div className="flex">
          <SocialShare
              url={normalize(`/${post.uri}`)}
              title={post.title}
              media={media}
            />
          </div>

        </div>
      </div>
      <div id="inject-comments-for-uterances" />
    </article>
    </>
  )
}
