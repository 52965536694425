import React from "react";
import { Widget } from "./widgets";
import clsx from "clsx";
import { Link } from "gatsby";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

const getSocialIcon = (platform) => {
  switch (platform) {
    case "facebook":
      return <FaFacebook />;
    case "instagram":
      return <FaInstagram />;
    case "linkedIn":
      return <FaLinkedin />;
    case "twitter":
      return <FaTwitter />;
    case "youTube":
      return <FaYoutube />;
    default:
      return null;
  }
};

export const Sidebar = ({ widgets, author, className, ...props }) => {
  const authorImageSize = 170;
  const socials = author.seo.social;
  const hasSocial = Object.values(socials).some((i) => Boolean(i));

  return (
    <>
      {widgets && widgets.length > 0 && (
        <aside
          className={clsx(
            "sidebar__inner",
            "px-5 py-10 sm:p-10 space-y-5",
            "bg-gray-800 rounded-lg shadow-lg text-gray-300",
            className
          )}
          style={{
            position: 'sticky',
            top: 0,
          }}
          {...props}
        >
          <div className="text-center card px-4 pt-2 pb-6 mb-12">
            <Link to={author.uri}>
              <img
                src={`${author.avatar.url.replace("s=96", "s=300")}`}
                alt={author.name}
                width={authorImageSize}
                height={authorImageSize}
                className="mx-auto -mt-7 rounded-full transition shadow-lg mb-6"
                style={{
                  border: "2px solid transparent",
                  borderRadius: "50%",
                  backgroundImage: "linear-gradient(90deg, #8f3b76 0%, #ff2c55 100%)",
                  backgroundClip: "padding-box",
                  padding: "2px",
                }}
              />
            </Link>
            <p className="uppercase tracking-widest text-base bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 to-transparent text-transparent bg-clip-text font-bold shadow-lg hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300">
              About the author
            </p>
            <Link
              to={author.uri}
              className="text-xl tracking-widest text-gray-200 !mt-2 mb-2 inline-block hover:underline"
            >
              {author.name}
            </Link>
            {/* Author description */}
            {author.description && (
              <div
                className="pb-4 text-base"
                dangerouslySetInnerHTML={{ __html: author.description }}
                {...props}
              />
            )}
            {/* Author socials */}
            {hasSocial && (
              <div className="flex flex-row flex-wrap justify-center space-x-2 mb-6">
                {Object.keys(socials).map((key) => {
                  if (!socials[key]) return null;

                  return (
                    <a
                      key={key}
                      href={socials[key]}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={`Follow on ${key}`}
                      className="text-2xl text-gray-400 hover:text-gray-200 transition duration-300"
                    >
                      {getSocialIcon(key)}
                    </a>
                  );
                })}
              </div>
            )}
            {/* Read Authors posts button */}
           
            <Link
              to={author.uri}
              className="btn btn-author inline-block py-2 px-5 text-white rounded-lg"
              style={{
                background: "linear-gradient(90deg, #8f3b76 0%, #ff2c55 100%)",
              }}
            >
              See all posts
            </Link>
          </div>
          {widgets.map((widget, i) => (
            <div key={i} className="my-5 first:mt-0">
              <Widget widget={widget} lightBg />
            </div>
          ))}
          <div className="mb-4"></div>
        </aside>
      )}
    </>
  );
};
