import React from "react";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import PinterestIcon from "@material-ui/icons/Pinterest";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RedditIcon from "@material-ui/icons/Reddit";
import HackerNewsIcon from "@material-ui/icons/Whatshot";

const SocialShare = ({ url, title, media }) => {
  return (
    <div className="mt-10">
      <div className="flex flex-wrap gap-4">
        <a
          href={`https://twitter.com/intent/tweet?url=${url}&text=${title}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded transition duration-300 transform hover:scale-110 no-underline"
        >
          <span className="mr-1 text-sm">Share</span>
          <TwitterIcon fontSize="small" />
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-2 py-1 bg-blue-700 hover:bg-blue-800 text-white rounded transition duration-300 transform hover:scale-110 no-underline"
        >
          <span className="mr-1 text-sm">Share</span>
          <FacebookIcon fontSize="small" />
        </a>
        <a
          href={`https://pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${title}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-2 py-1 bg-red-600 hover:bg-red-700 text-white rounded transition duration-300 transform hover:scale-110 no-underline"
        >
          <span className="mr-1 text-sm">Share</span>
          <PinterestIcon fontSize="small" />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-2 py-1 bg-blue-800 hover:bg-blue-900 text-white rounded transition duration-300 transform hover:scale-110 no-underline"
        >
          <span className="mr-1 text-sm">Share</span>
          <LinkedInIcon fontSize="small" />
        </a>
        <a
          href={`http://www.reddit.com/submit?url=${url}&title=${title}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-2 py-1 bg-orange-600 hover:bg-orange-700 text-white rounded transition duration-300 transform hover:scale-110 no-underline"
        >
          <span className="mr-1 text-sm">Share</span>
          <RedditIcon fontSize="small" />
        </a>
        <a
          href={`https://news.ycombinator.com/submitlink?u=${url}&t=${title}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center px-2 py-1 bg-yellow-600 hover:bg-yellow-700 text-white rounded transition duration-300 transform hover:scale-110 no-underline"
        >
          <span className="mr-1 text-sm">Share</span>
          <HackerNewsIcon fontSize="small" />
        </a>
      </div>
   
      </div>
  );
};
export default SocialShare;